import gql from 'graphql-tag';

export const ALERT_LIST_QUERY = gql`    
     query listAlerts {
        listAlerts {
            PK
            alerttitle
            blacklist_users
            link
            message
            source
            time
            whitelist_groups
            alert_type
        }
    }
`;