import gql from 'graphql-tag';

export const MANAGE_USER_MUTATION = gql`
    mutation manageUser($username: String!, $action: UserManagementAction!) {
        manageUser(user: $username, action: $action) {
            success
            message
        }
    }
`;
export const MANAGE_USER_VARIABLES = ["username", "action"];