import gql from 'graphql-tag';

export const DELETE_GROUP_MUTATION = gql`
mutation deleteGroup($SK: String!, $Roles: [String]!) {
    deleteGroup(groupattributes: {SK: $SK, Roles: $Roles}){
        success
        users
    }
  }
`;
export const DELETE_GROUP_VARIABLES = ["SK", "Roles"];