import gql from 'graphql-tag';

export const GROUP_LIST_QUERY = gql`
query getGroups {
    getGroups {
        SK
        name
        Roles
    }
}
`;