<i18n src="./lang.json"></i18n>
<template>
    <v-card id="csNotificationHistoryContainer">
        <v-card-title class="pa-0">
            <v-toolbar class="csGradientBlueBackground fullscreenDialogToolbar mb-3 pa-0" flat>
                <v-toolbar-title class="pa-0 fullscreenDialogToolbarTitle">
                    <v-list-item-avatar class="notificationHistoryAvatar" color="white" size="60px">
                        <v-icon size="24">{{ $csBasicsIcons.messageExclamation }}</v-icon>
                    </v-list-item-avatar>
                    <span style="color: white">{{ $t('notificationHistory') }}</span>
                    <v-spacer></v-spacer>
                    <cs-action-button icon-color="white" :icon="$csBasicsIcons.closeFontawesome"
                                      :action="() => $emit('notificationHistoryClosed')"
                                      :tooltip="$t('close')" icon-size="22" class="mr-1"
                    ></cs-action-button>
                </v-toolbar-title>
            </v-toolbar>
        </v-card-title>
        <v-card-text>
            <div style="display: flex; align-items: center; margin-top: 20px" v-if="!notificationHistory.length > 0">
                <cs-icon-circle :icon="$csBasicsIcons.informationNew"></cs-icon-circle>
                <span class="ml-3 csSubHeading">{{ $t('noRecentNotifications') }}</span>
            </div>
            <div v-else-if="notificationHistory.length > 0">
                <div style="display: flex; align-items: center">
                    <span class="csSubHeading mb-2" v-if="!selectedNotificationType && !oldestFirst">{{ $t("allNotifications") }}</span>
                    <div v-else>
                        <v-chip outlined class="chip" close @click:close="showError = false" v-if="showError">{{ $t('error') }}</v-chip>
                        <v-chip outlined class="chip" close @click:close="showInfo = false" v-if="showInfo">{{ $t('info') }}</v-chip>
                        <v-chip outlined class="chip" close @click:close="showSuccess = false" v-if="showSuccess">{{ $t('success') }}</v-chip>
                        <v-chip outlined class="chip" close @click:close="showWarn = false" v-if="showWarn">{{ $t('warn') }}</v-chip>
                        <v-chip outlined class="chip" close @click:close="oldestFirst = false" v-if="oldestFirst">{{ $t('oldestFirst') }}</v-chip>
                    </div>
                    <v-spacer></v-spacer>
                    <v-menu :close-on-content-click="false" v-model="showFilterMenu"
                            rounded right offset-x
                    >
                        <template #activator="{ on }">
                            <cs-action-button
                                :tooltip="$t('filterNotifications')"
                                :icon-color="selectedNotificationType || oldestFirst ? $csBasicsColors.csPrimaryDarkBlue : $csBasicsColors.csDark"
                                :icon="$csBasicsIcons.filter" move-tooltip-by="10"
                                icon-size="24"
                                class="mb-2 mr-1"
                                @actionButtonClicked="showFilterMenu = true"
                                v-on="on">
                            </cs-action-button>
                        </template>
                        <div style="width: 350px; background-color: white; display: flex; flex-direction: column" class="filter pa-0" >
                            <div style="display: flex; align-items: center; justify-content: space-between; padding: 0 16px" class="csGradientBlueBackground">
                                <v-subheader style="color: #F8F8F8" class="pa-0 csSubHeading widget-subheader">{{ $t('filterNotifications') }}</v-subheader>
                                <div style="display: flex; align-items: center">
                                    <cs-action-button @actionButtonClicked="showFilterMenu = false"
                                                      :icon="$csBasicsIcons.closeMaterial"
                                                      :tooltip="$t('close')"
                                                      :icon-color="$csBasicsColors.csLight"
                                                      icon-size="22">
                                    </cs-action-button>
                                </div>
                            </div>
                            <div v-bind:style="[ selectedNotificationType || oldestFirst ? { paddingTop: '6px' } : {paddingTop: '16px'}]"
                                 style="display: flex; flex-direction: column; padding: 16px">
                                <div style="display: flex; justify-content: center">
                                    <v-btn v-if="selectedNotificationType || oldestFirst"
                                        @click="resetFilter" block
                                        style="text-transform: capitalize; min-width: 100%" text
                                        :color="$csBasicsColors.csPrimaryDarkBlue">
                                        {{ $t('resetTooltip') }}
                                    </v-btn>
                                </div>
                                <v-list-item class="pa-0">
                                    <div style="display: flex; flex-direction: column; width: 100%">
                                        <span class="csSubHeading csMediumText mb-1">{{ $t('selectNotificationType') }}:</span>
                                        <div style="display: flex; justify-content: space-between">
                                            <div>
                                                <v-switch hide-details class="switch ma-0 mb-2 mr-2" v-model="showError" :label="$t('error')"></v-switch>
                                                <v-switch hide-details class="switch ma-0 mb-2 mr-2" v-model="showWarn" :label="$t('warn')"></v-switch>
                                            </div>
                                            <div>
                                                <v-switch hide-details class="switch ma-0 mb-2 mr-2" v-model="showInfo" :label="$t('info')"></v-switch>
                                                <v-switch hide-details class="switch ma-0 mb-2 mr-2" v-model="showSuccess" :label="$t('success')"></v-switch>
                                            </div>
                                        </div>
                                    </div>
                                </v-list-item>
                                <v-list-item class="pa-0">
                                    <div style="display: flex; flex-direction: column; align-items: start">
                                        <span class="csSubHeading csMediumText mt-2 mb-1">{{ $t('setOrder') }}:</span>
                                        <v-switch hide-details class="switch ma-0 mr-2" v-model="oldestFirst" :label="$t('oldestFirst')"></v-switch>
                                    </div>
                                </v-list-item>
                            </div>
                        </div>
                    </v-menu>
                </div>
                <v-divider style="margin-bottom: 20px"></v-divider>
                <div v-if="notificationHistory.length > 0 && !notifications.length > 0" style="display: flex; align-items: center">
                    <cs-icon-circle :icon="$csBasicsIcons.informationNew"></cs-icon-circle>
                    <span class="ml-3 csSubHeading">{{ $t('noResultsForFilter') }}</span>
                </div>
                <cs-perfect-scrollbar v-else height="74vh">
                    <template slot="perfectScrollbarContent">
                        <div id="csNotificationsMessagesWrapper">
                            <div v-for="(notification, index) in notifications" :key="index">
                                <v-hover v-slot="{ hover }" open-delay="150">
                                    <div style="display: flex; align-items: center">
                                        <cs-inner-widget-card class="pt-0 pl-0" style="width: 100%;">
                                            <template slot="csInnerWidgetCardMainContent">
                                                <div class="notificationContainer">
                                                    <div>
                                                        <v-icon style="width: 50px"  :color="notificationIconColor(notification)">{{ notificationIcon(notification) }}</v-icon>
                                                    </div>
                                                    <div style="display: flex; flex-direction: column">
                                                        <span  class="csSubHeading">{{ notification.title }}</span>
                                                        <span class="csText csLightText">{{ notification.message }}</span>
                                                        <span class="csText csLightText">{{ $csBasicsFormat.formatDate(notification.timestamp) }} {{ $t('at') }} {{ $csBasicsFormat.formatTime(notification.timestamp) }}</span>
                                                    </div>
                                                </div>
                                            </template>
                                        </cs-inner-widget-card>
                                        <cs-action-button :class="{'on-hover': hover}" class="removeNotificationButton"
                                                          :icon="$csBasicsIcons.deleteMaterial"
                                                          @actionButtonClicked="removeNotification(notification.id)"
                                                          :tooltip="$t('remove')"
                                                          :icon-color="$csBasicsColors.csDark"
                                                          icon-size="22">
                                        </cs-action-button>
                                    </div>
                                </v-hover>
                            </div>
                        </div>
                    </template>
                </cs-perfect-scrollbar>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    data() {
        return {
            showError: false,
            showWarn: false,
            showInfo: false,
            showSuccess: false,
            oldestFirst: false,
            showFilterMenu: false,
            notificationHistory: [],
        };
    },
    mounted() {
        this.notificationHistory = this.getNotificationHistory();
    },
    methods: {
        /**
         * Method to return the notification history
         */
        getNotificationHistory(){
            return this.$csNotificationHistory.getNotificationHistory();
        },
        /**
         * Removes a notification from the notification history
         */
        removeNotification(id) {
            this.$csNotificationHistory.removeNotification(id);
        },
        /**
         * Resets all filter parameters to their default
         */
        resetFilter(){
            this.showError = false;
            this.showWarn = false;
            this.showSuccess = false;
            this.showInfo = false;
            this.oldestFirst = false;
        },
        /**
         * Filters notification by selected type and orders them ascending or descending in regards to their creation timestamp
         */
        filterAndSortNotifications(notifications){
            let filteredNotifications = [];
            notifications.forEach(notification => {
                if(this.selectedNotificationType){
                    if(this.showWarn){
                        if(notification.type === 'warn'){
                            filteredNotifications.push(notification);
                        }
                    }
                    if (this.showInfo){
                        if(notification.type === 'info'){
                            filteredNotifications.push(notification);
                        }
                    }
                    if(this.showError){
                        if(notification.type === 'error'){
                            filteredNotifications.push(notification);
                        }
                    }
                    if(this.showSuccess){
                        if(notification.type === 'success'){
                            filteredNotifications.push(notification);
                        }
                    }
                } else {
                    filteredNotifications.push(notification);
                }
            });
            filteredNotifications.sort((a, b) => {
                if(!this.oldestFirst){
                    return b.timestamp - a.timestamp;
                }else if(this.oldestFirst){
                    return a.timestamp - b.timestamp;
                }
            });
            return filteredNotifications;
        },
        /**
         * Returns icon color depending on notification type
         * @param notification
         * @returns {string}
         */
        notificationIconColor(notification){
            let color;
            if(notification.type === 'info'){
                color = this.$csBasicsColors.csPrimaryDarkBlue;
            } else if(notification.type === 'warn'){
                color = this.$csBasicsColors.csAccentOrange;
            } else if(notification.type === 'error'){
                color = this.$csBasicsColors.csAccentRed;
            } else if(notification.type === 'success'){
                color = this.$csBasicsColors.csAccentGreen;
            }
            return color;
        },
        /**
         * Returns icon depending on notificationType
         * @param notification
         * @returns {string}
         */
        notificationIcon(notification){
            let icon;
            if(notification.type === 'info'){
                icon = this.$csBasicsIcons.informationNew;
            } else if(notification.type === 'warn'){
                icon = this.$csBasicsIcons.warningNew;
            } else if(notification.type === 'error'){
                icon = this.$csBasicsIcons.errorNew;
            } else if(notification.type === 'success'){
                icon = this.$csBasicsIcons.successNew;
            }
            return icon;
        }
    },
    computed: {
        /**
         * Checks if a filter for a notification type is selected
         */
        selectedNotificationType(){
            return this.showError || this.showSuccess || this.showInfo || this.showWarn;
        },
        /**
         * Returns filtered + sorted notifications for the UI
         * @returns {*[]}
         */
        notifications(){
            return this.filterAndSortNotifications(this.notificationHistory);
        }
    },
};
</script>

<style lang="scss" scoped>

#csNotificationHistoryContainer{

    .fullscreenDialogToolbar {
        padding-top: 7.5%;
        padding-bottom: 5%;
        position: sticky !important;
        top: 0;
        z-index: 999;
        height: 100px !important;

        .fullscreenDialogToolbarTitle {
            color: $cs-dark;
            padding-top: 80px;
            font-weight: normal;
            font-size: 24px;
            width: 100%;
            display: flex;
            align-items: center;

            .notificationHistoryAvatar i {
                font-size: 5rem;
                font-style: normal;
                background: rgba(0, 0, 0, 0) linear-gradient(to left bottom, rgb(3, 152, 226), rgb(2, 67, 100)) repeat scroll 0 0;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                -webkit-animation: fontgradient 2s ease infinite;
                -moz-animation: fontgradient 2s ease infinite;
            }
        }
    }
    .removeNotificationButton {
        transition: opacity .4s ease-in-out;

    }
    .removeNotificationButton:not(.on-hover) {
        opacity: 0;
    }
    .chip {
        color: $cs-primary-dark-blue;
        margin: 0 5px 10px 5px;
        border-color: $cs-primary-dark-blue;
    }

    #csNotificationsMessagesWrapper > div:nth-child(even)  {
        .csInnerWidgetCard {
            border-top: none;
            border-bottom: none;
        }
    }
    #csNotificationsMessagesWrapper > div:first-child  {
        .csInnerWidgetCard {
            border-top: thin solid rgba(0,0,0,0.12);
        }
    }
    #csNotificationsMessagesWrapper > div:last-child  {
        .csInnerWidgetCard {
            border-bottom: thin solid rgba(0,0,0,0.12);
        }
    }
    .notificationContainer {
        display: flex;
        align-items: center;
        width: 100%;
    }
}
</style>
<style lang="scss">

.chip .v-chip__close {
    color: $cs-primary-dark-blue !important;
}
.switch .v-label {
    color: $cs-dark !important;
}
.filter .v-input--is-label-active  .v-input--switch__track {
    color: $cs-primary-dark-blue !important;
}
.filter .v-input--switch__track {
    color: $cs-dark !important;
}
.filter .v-input--is-label-active .v-input--switch__thumb {
    color: $cs-primary-dark-blue !important;
}
.filter .v-input--switch__thumb {
    color: $cs-background !important;
}

</style>