import gql from 'graphql-tag';

export const UPDATE_GROUP_MUTATION = gql`
mutation updateGroup($Roles: [String]!, $SK: String!, $name: String!, $RemovedRoles: [String]!) {
  updateGroup(groupattributes: {Roles: $Roles, SK: $SK, name: $name, RemovedRoles: $RemovedRoles}) {
    SK
    name
    Roles
  }
}
`;
export const UPDATE_GROUP_VARIABLES = ["Roles", "SK", "name", "RemovedRoles"];