import gql from 'graphql-tag';

export const DEPLOYED_SERVICES_QUERY = gql`
  query getDeployedServices {
    getDeployedServices{
        service
        appsync_endpoint
        parameters 
    }
  }
`;