var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"csEditRoleComponent"}},[_c('v-overlay',{attrs:{"value":_vm.loading,"absolute":true,"opacity":0.07}},[_c('cs-progress-circular')],1),_c('v-list',[_c('v-text-field',{attrs:{"tabindex":"2","label":_vm.$t('name'),"rules":[function (v) { return !!v || _vm.$t('itemIsRequired'); }]},on:{"input":function($event){return _vm.dirtyChanger(true)}},model:{value:(_vm.item.name),callback:function ($$v) {_vm.$set(_vm.item, "name", $$v)},expression:"item.name"}}),(_vm.containsAdminPermission)?_c('v-list-item',{staticClass:"pa-0"},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',{staticClass:"ma-0",attrs:{"color":_vm.$csBasicsColors.csPrimaryDarkBlue}},[_vm._v(_vm._s(_vm.$csBasicsIcons.informationNew)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"csSubHeading"},[_vm._v(_vm._s(_vm.$t('adminPermission')))])],1)],1):_vm._e(),(!_vm.containsAdminPermission)?_c('v-list-item',{staticClass:"pa-0 mb-n2"},[_c('v-list-item-title',{staticClass:"csSubHeading"},[_vm._v(_vm._s(_vm.$t('applicationAccess')))])],1):_vm._e(),(!_vm.containsAdminPermission)?_c('treeselect',{attrs:{"disabled":_vm.isReadOnly,"options":_vm.applicationRightsTree,"multiple":true,"defaultExpandLevel":Infinity,"sortValueBy":"INDEX","clearable":false,"backspaceRemoves":false,"deleteRemoves":false,"placeholder":_vm.$t('select'),"noOptionsText":_vm.$t('noOptions'),"noResultsText":_vm.$t('noResult'),"appendToBody":true,"maxHeight":300},on:{"select":function($event){return _vm.dirtyChanger(true)},"deselect":function($event){return _vm.dirtyChanger(true)}},scopedSlots:_vm._u([{key:"option-label",fn:function(ref){
var node = ref.node;
return [(node.raw.tooltip)?_c('cs-tooltip',{attrs:{"right":"","open-tooltip-delay":150}},[_c('template',{slot:"tooltipActivator"},[_c('span',{staticClass:"option-label csSubHeading"},[_vm._v(_vm._s(_vm.$t(node.label)))])]),_c('template',{slot:"tooltipContent"},[_c('span',[_vm._v(_vm._s(_vm.$t(node.raw.tooltip)))])])],2):_c('span',{staticClass:"option-label csSubHeading"},[_vm._v(_vm._s(_vm.$t(node.label)))])]}},{key:"value-label",fn:function(ref){
var node = ref.node;
return [_c('span',{staticClass:"value-label csText"},[_vm._v(_vm._s(_vm.translateList(node.raw.path)))])]}}],null,false,1108268972),model:{value:(_vm.selectedApplicationRights),callback:function ($$v) {_vm.selectedApplicationRights=$$v},expression:"selectedApplicationRights"}}):_vm._e(),(!_vm.containsAdminPermission)?_c('v-list-item',{staticClass:"pt-5 pl-0 pr-0 mb-n2"},[_c('v-list-item-title',{staticClass:"csSubHeading"},[_vm._v(_vm._s(_vm.$t('featureRights')))])],1):_vm._e(),(!_vm.containsAdminPermission)?_c('treeselect',{attrs:{"disabled":_vm.isReadOnly,"options":_vm.featureRightsTree,"multiple":true,"defaultExpandLevel":1,"sortValueBy":"INDEX","clearable":false,"backspaceRemoves":false,"deleteRemoves":false,"placeholder":_vm.$t('select'),"noOptionsText":_vm.$t('noOptions'),"noResultsText":_vm.$t('noResult')},on:{"select":function($event){return _vm.dirtyChanger(true)},"deselect":function($event){return _vm.dirtyChanger(true)}},scopedSlots:_vm._u([{key:"option-label",fn:function(ref){
var node = ref.node;
return [(node.raw.tooltip)?_c('cs-tooltip',{attrs:{"right":"","open-tooltip-delay":150}},[_c('template',{slot:"tooltipActivator"},[_c('span',{staticClass:"option-label csSubHeading"},[_vm._v(_vm._s(_vm.$t(node.label)))])]),_c('template',{slot:"tooltipContent"},[_c('span',[_vm._v(_vm._s(_vm.$t(node.raw.tooltip)))])])],2):_c('span',{staticClass:"option-label csSubHeading"},[_vm._v(_vm._s(_vm.$t(node.label)))])]}},{key:"value-label",fn:function(ref){
var node = ref.node;
return [_c('span',{staticClass:"value-label csText"},[_vm._v(_vm._s(_vm.translateList(node.raw.path)))])]}}],null,false,1108268972),model:{value:(_vm.selectedFeatureRights),callback:function ($$v) {_vm.selectedFeatureRights=$$v},expression:"selectedFeatureRights"}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }