import gql from 'graphql-tag';

export const INSERT_NEW_GROUP_MUTATION = gql`
mutation insertNewGroup ($SK: String!, $Roles: [String]!){
    createGroup(groupattributes: {SK: $SK, Roles: $Roles}) {
      SK
      name
      Roles
    }
  }
`;
export const INSERT_NEW_GROUP_VARIABLES = ["SK", "Roles"];