import gql from 'graphql-tag';

export const INSERT_NEW_ROLE_MUTATION = gql`
mutation insertNewRole ($SK: String!){
    createRole(roleattributes: {SK: $SK}) {
      SK
      name
    }
  }
`;
export const INSERT_NEW_ROLE_VARIABLES = ["SK"];