import { csbaseAuthModule } from "./csbase-auth.module";
import { csbaseAppIntegrationModule } from "./csbase-app-integration.module";
// import { csbaseAlertsModule } from "@/store/csbase-alerts-wrapper.module";
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const modules = {
    csbaseAuthModule,
    csbaseAppIntegrationModule,
    // csbaseAlertsModule
};

const store = new Vuex.Store({
    modules: modules
});

export default store;
