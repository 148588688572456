import gql from 'graphql-tag';

export const PERMISSION_DESCRIPTORS_QUERY = gql`
query getPermissionDescriptors {
    getPermissionDescriptors {
        PK
        de
        en
    }
}
`;