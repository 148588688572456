import { render, staticRenderFns } from "./AlertHistoryList.vue?vue&type=template&id=59779092&scoped=true&"
import script from "./AlertHistoryList.vue?vue&type=script&lang=js&"
export * from "./AlertHistoryList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59779092",
  null
  
)

/* custom blocks */
import block0 from "./lang.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fcodebuild%2Foutput%2Fsrc2227804044%2Fsrc%2Fsrc%2Fcomponents%2Fcsbase-alerts%2Fcsbase-alert-content%2Fcsbase-alert-history%2FAlertHistoryList.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports