import gql from 'graphql-tag';

export const INSERT_NEW_USER_MUTATION = gql`
    mutation insertNewUser ($email: String!, $family_name: String!, $given_name: String!, $groups: [String!]!, $phone_number: String, $username: String!){
        insertNewUser(newuser: {email: $email, family_name: $family_name, given_name: $given_name, groups: $groups, phone_number: $phone_number, username: $username}) {
            account_status
            created
            email
            family_name
            given_name
            groups
            last_modified
            phone_number
            username
            user_status
        }
    }
`;
export const INSERT_NEW_USER_VARIABLES = ["email", "family_name", "given_name", "groups", "phone_number", "username"];